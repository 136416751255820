import Award1 from '../../assets/images/awards/Award_1.jpeg'
import Award2 from '../../assets/images/awards/Award_2.jpeg'

export const awardsData = [
    {
        mediaType: 'Best Paper Award',
        subject: 'Society of Biological Psychiatry: Best-Paper Award',
        peopleInvolved: 'C.S. Sergiou',
        date: '2024',
        image: Award1,
    },
    {
        mediaType: 'Best Cover Award',
        subject:
            "Biological Psychiatry: Cognitive Neuroscience and Imaging's Best Cover Award",
        peopleInvolved: 'C.S.Sergiou',
        date: '2022',
        image: Award2,
    },
]
