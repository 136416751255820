import HorizonNeuroscience from '../../assets/images/Projects2/HorizonNeuroscience.png'
import neuroYouth from '../../assets/images/Projects2/neuroYouth.jpeg'
import psycopath from '../../assets/images/Projects2/psycopath.png'
import Virtual_Burglary from '../../assets/images/Projects2/Virtual_Burglary.avif'
import Neuromodulation from '../../assets/images/Projects2/brainneuromodilation.png'
import KetamineDepression from '../../assets/images/Projects2/ketamineDepression.webp'
import Brain7 from '../../assets/images/brains/Brain7.webp'
import Brain2 from '../../assets/images/brains/Brain2.webp'
import Brain9 from '../../assets/images/brains/Brain9.webp'
import Brain4 from '../../assets/images/brains/Brain4.webp'
import Brain8 from '../../assets/images/brains/Brain8.webp'

export const projectDataPresent = [
    {
        title: 'Growing Up in Society Together (GUTS)',
        description:
            'Postdoctoral researcher at Amsterdam UMC・Longitudinal study into the neuropsychological development of high-risk antisocial youth ',
        link: 'https://www.gutsproject.com/work-package/antisocial-behaviour/',
        date: '2024 - Current',
        image: neuroYouth,
        color: '#d9964c',
        backgroundColor: '#c5d7c9',
    },
    {
        title: 'Virtual Burglary Project',
        description:
            'Postdoctoral researcher・VR study into investigating criminal decision-making in burglars',
        link: 'https://csl.mpg.de/en/projects/virtual-burglary-project',
        date: '2022 - Current',
        image: Brain7,
        color: '#759f7e',
        backgroundColor: '#cdd5e0',
    },
    {
        title: 'Horizon Neuroscience (Boston)',
        description: 'Researchteam・Solutions for Brain Health',
        link: 'https://horizon-neuro.com/',
        date: '2023 - Current',
        image: HorizonNeuroscience,
        color: '#6a92c5',
        backgroundColor: '#f8e9e6',
    },
    {
        title: 'Aftermath Psychopathy Foundation',
        description:
            'Head of translations・Website to inform victims of psychopatic individuals',
        link: 'https://aftermath-surviving-psychopathy.org/',
        date: '2019 - Current',
        image: Brain9,
        color: '#db9f93',
        backgroundColor: '#f3d3b0',
    },
    {
        title: 'Open Foundation',
        description:
            'Co-author・Rapport on using ketamine in treatment-resistent depression ',
        link: 'https://open-foundation.org/',
        image: Brain2,
        date: '2022 - 2023',
        color: '#da964e',
        backgroundColor: '#d8c0c0',
    },
    {
        description:
            'Neuromodulation to Reduce Aggression and Increase Empathic Abilities',
        title: 'PhD project - Unraveling the Aggressive Brain',
        link: 'https://pure.eur.nl/en/publications/understanding-the-aggressive-brain-high-definition-transcranial-d',
        date: '2017 - 2022',
        image: Neuromodulation,
        color: '#935b5b',
        backgroundColor: '#d7cec5',
    },
]
