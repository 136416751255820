import Brain7 from '../../assets/images/brains/Brain7.webp'
import Brain2 from '../../assets/images/brains/Brain2.webp'
import Brain9 from '../../assets/images/brains/Brain9.webp'
import Brain4 from '../../assets/images/brains/Brain4.webp'
import Brain8 from '../../assets/images/brains/Brain8.webp'

export const mediaData = [
    {
        mediaType: 'Podcast',
        subject: 'DPECS -Meeting the Future Society – PhD research',
        peopleInvolved: 'C.S. Sergiou',
        link: 'https://open.spotify.com/episode/48hiphLoEg6vIcmX5Vfgam',
        date: '15-11-2022',
        image: Brain7,
    },
    {
        mediaType: 'Live Talkshow',
        subject: 'Studio Erasmus - Neuromodulation to Reduce Aggression',
        peopleInvolved: 'C.S.Sergiou & Josanne van Dongen',
        link: 'https://www.youtube.com/watch?v=ct2KAOHN4Xg',
        date: '29-11-2023',
        image: Brain2,
    },
    {
        mediaType: 'Live Radioshow',
        subject: 'Dr. Kelder & Co – Radio 1 Show – Modulating Aggression.',
        peopleInvolved: 'C.S.Sergiou ',
        link: 'https://www.nporadio1.nl/fragmenten/dr-kelder-en-co/adc2cb07-5bfa-4d73-bbc1-eaa7c8ec2aee/2022-06-09-boefjes-in-toom-houden-met-elektroshocks',
        date: '11 juni 2022',
        image: Brain9,
    },
    {
        mediaType: 'Blogpost',
        subject: 'Inbraakpreventie met behulp van Virtual Reality',
        peopleInvolved: 'C.S.Sergiou & Nick Weessies',
        link: 'https://www.leidenpedagogiekblog.nl/articles/inbraakpreventie-met-behulp-van-virtual-reality',
        date: '19 juni 2023',
        image: Brain8,
    },
    {
        mediaType: 'Informative video',
        subject: 'Max Planck Institute- Virtual Burglary Project',
        peopleInvolved:
            'C.S.Sergou, Jean-Louis van Gelder, Peter Wozniak, Timothy Barnum, Dominik Gerstner, PI Alphen aan den Rijn',
        link: '',
        date: 'december 2022',
        image: Brain4,
    },
]
